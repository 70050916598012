var render = function render(){var _vm=this,_c=_vm._self._c;return _c('g',[_c('g',{attrs:{"id":"g135"}},[_c('g',{attrs:{"id":"g137","clip-path":"url(#clipPath141)"}},[_c('g',{directives:[{name:"show",rawName:"v-show",value:(_vm.btnToggle != 2),expression:"btnToggle != 2"}]},[_c('text',{staticStyle:{"font-variant":"normal","font-weight":"bold","font-size":"200px","font-family":"Arial","-inkscape-font-specification":"ArialMT","writing-mode":"lr-tb","fill":"#000000","fill-opacity":"1","fill-rule":"nonzero","stroke":"none"},attrs:{"transform":"matrix(1,0,0,-1,11000,12605)","id":"text229"}},[_c('tspan',{attrs:{"x":"0","y":"0","id":"tspan227"}},[_vm._v("Receptacles and Probes")])]),_vm._l((_vm.receptacleGroup),function(receptacle,index){return _c('g',{key:`receptacle${index}`},[_c('circle',{attrs:{"cx":`${_vm.start_x.circle}`,"cy":`${_vm.start_y.circle - 500 * (index + 1)}`,"r":"180","stroke":receptacle.color,"stroke-width":"40","fill":"white","id":`r${index}`}}),_c('text',{staticStyle:{"font-variant":"normal","font-weight":"normal","font-size":"150px","font-family":"Arial","-inkscape-font-specification":"ArialMT","writing-mode":"lr-tb","fill":"#000000","fill-opacity":"1","fill-rule":"nonzero","stroke":"none"},attrs:{"transform":`matrix(1,0,0,-1,${_vm.start_x.text},${_vm.start_y.text -
                500 * (index + 1)})`,"id":`gr${index}`}},[_c('tspan',{attrs:{"x":"0","y":"0","id":"tspan227"}},[_vm._v(_vm._s(`${receptacle.description} - ${receptacle.part_number}, qty ${receptacle.count}`)+" ")])])])}),_vm._l((_vm.probeGroup),function(probe,index){return _c('g',{key:`probe${index}`},[_c('circle',{attrs:{"cx":`${_vm.start_x.circle}`,"cy":`${_vm.start_y.circle - 500 * (index + 1 + _vm.receptacleGroup.length)}`,"r":"200","fill":probe.color,"id":`p${index}`}}),_c('text',{staticStyle:{"font-variant":"normal","font-weight":"normal","font-size":"150px","font-family":"Arial","-inkscape-font-specification":"ArialMT","writing-mode":"lr-tb","fill":"#000000","fill-opacity":"1","fill-rule":"nonzero","stroke":"none"},attrs:{"transform":`matrix(1,0,0,-1,${_vm.start_x.text},${_vm.start_y.text -
                500 * (index + 1 + _vm.receptacleGroup.length)})`,"id":`gp${index}`}},[_c('tspan',{attrs:{"x":"0","y":"0","id":"tspan227"}},[_vm._v(_vm._s(`${probe.description} - ${probe.part_number}, qty ${probe.count}`))])])])}),_c('text',{staticStyle:{"font-variant":"normal","font-weight":"bold","font-size":"200px","font-family":"Arial","-inkscape-font-specification":"ArialMT","writing-mode":"lr-tb","fill":"#000000","fill-opacity":"1","fill-rule":"nonzero","stroke":"none"},attrs:{"transform":`matrix(1,0,0,-1,11000,${_vm.start_y.text -
              500 * (1.5 + _vm.receptacleGroup.length + _vm.probeGroup.length)})`,"id":"text229"}},[_c('tspan',{attrs:{"x":"0","y":"0","id":"tspan227"}},[_vm._v("Guide Pins")])]),_vm._l((_vm.guidepinGroup),function(guide,index){return _c('g',{key:`guide${index}`},[_c('circle',{attrs:{"cx":`${_vm.start_x.circle}`,"cy":`${_vm.start_y.circle -
                500 *
                  (index + 2.5 + _vm.receptacleGroup.length + _vm.probeGroup.length)}`,"r":"200","fill":guide.color,"id":`g${index}`}}),_c('text',{staticStyle:{"font-variant":"normal","font-weight":"normal","font-size":"150px","font-family":"Arial","-inkscape-font-specification":"ArialMT","writing-mode":"lr-tb","fill":"#000000","fill-opacity":"1","fill-rule":"nonzero","stroke":"none"},attrs:{"transform":`matrix(1,0,0,-1,${_vm.start_x.text},${_vm.start_y.text -
                500 *
                  (index +
                    2.5 +
                    _vm.receptacleGroup.length +
                    _vm.probeGroup.length)})`,"id":`gt${index}`}},[_c('tspan',{attrs:{"x":"0","y":"0","id":"tspan227"}},[_vm._v(_vm._s(`${guide.description} - ${guide.part_number}, qty ${guide.count}`))])])])})],2),_c('g',{directives:[{name:"show",rawName:"v-show",value:(_vm.btnToggle != 1),expression:"btnToggle != 1"}]},[_c('text',{staticStyle:{"font-variant":"normal","font-weight":"bold","font-size":"200px","font-family":"Arial","-inkscape-font-specification":"ArialMT","writing-mode":"lr-tb","fill":"#000000","fill-opacity":"1","fill-rule":"nonzero","stroke":"none"},attrs:{"transform":`matrix(1,0,0,-1,11000,12605)`,"id":"text229"}},[_c('tspan',{attrs:{"x":"0","y":0,"id":"tspan227"}},[_vm._v("Pressure Pins")])]),_vm._l((_vm.pressurepinGroup),function(pressure,index){return _c('g',{key:`pressure${index}`},[_c('circle',{attrs:{"cx":`${_vm.start_x.circle}`,"cy":`${_vm.start_y.circle - 500 * (index + 1)}`,"r":"200","fill":pressure.color,"id":`g${index}`}}),_c('text',{staticStyle:{"font-variant":"normal","font-weight":"normal","font-size":"150px","font-family":"Arial","-inkscape-font-specification":"ArialMT","writing-mode":"lr-tb","fill":"#000000","fill-opacity":"1","fill-rule":"nonzero","stroke":"none"},attrs:{"transform":`matrix(1,0,0,-1,${_vm.start_x.text},${_vm.start_y.text -
                500 * (index + 1)})`,"id":`gt${index}`}},[_c('tspan',{attrs:{"x":"0","y":"0","id":"tspan227"}},[_vm._v(_vm._s(`${pressure.description} - ${pressure.part_number}, qty ${pressure.count}`))])])])})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }