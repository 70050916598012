<template>
  <g>
    <g id="g135"
      ><g id="g137" clip-path="url(#clipPath141)">
        <g v-show="btnToggle != 2">
          <text
            transform="matrix(1,0,0,-1,11000,12605)"
            style="font-variant:normal;font-weight:bold;font-size:200px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
            id="text229"
            ><tspan x="0" y="0" id="tspan227"
              >Receptacles and Probes</tspan
            ></text
          >
          <g
            v-for="(receptacle, index) in receptacleGroup"
            :key="`receptacle${index}`"
          >
            <circle
              :cx="`${start_x.circle}`"
              :cy="`${start_y.circle - 500 * (index + 1)}`"
              r="180"
              :stroke="receptacle.color"
              stroke-width="40"
              fill="white"
              :id="`r${index}`"
            />
            <text
              :transform="
                `matrix(1,0,0,-1,${start_x.text},${start_y.text -
                  500 * (index + 1)})`
              "
              style="font-variant:normal;font-weight:normal;font-size:150px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
              :id="`gr${index}`"
              ><tspan x="0" y="0" id="tspan227"
                >{{
                  `${receptacle.description} - ${receptacle.part_number}, qty ${receptacle.count}`
                }}
              </tspan></text
            >
          </g>
          <g v-for="(probe, index) in probeGroup" :key="`probe${index}`">
            <circle
              :cx="`${start_x.circle}`"
              :cy="
                `${start_y.circle - 500 * (index + 1 + receptacleGroup.length)}`
              "
              r="200"
              :fill="probe.color"
              :id="`p${index}`"
            />
            <text
              :transform="
                `matrix(1,0,0,-1,${start_x.text},${start_y.text -
                  500 * (index + 1 + receptacleGroup.length)})`
              "
              style="font-variant:normal;font-weight:normal;font-size:150px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
              :id="`gp${index}`"
              ><tspan x="0" y="0" id="tspan227">{{
                `${probe.description} - ${probe.part_number}, qty ${probe.count}`
              }}</tspan></text
            >
          </g>
          <text
            :transform="
              `matrix(1,0,0,-1,11000,${start_y.text -
                500 * (1.5 + receptacleGroup.length + probeGroup.length)})`
            "
            style="font-variant:normal;font-weight:bold;font-size:200px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
            id="text229"
            ><tspan x="0" y="0" id="tspan227">Guide Pins</tspan></text
          >
          <g v-for="(guide, index) in guidepinGroup" :key="`guide${index}`">
            <circle
              :cx="`${start_x.circle}`"
              :cy="
                `${start_y.circle -
                  500 *
                    (index + 2.5 + receptacleGroup.length + probeGroup.length)}`
              "
              r="200"
              :fill="guide.color"
              :id="`g${index}`"
            />
            <text
              :transform="
                `matrix(1,0,0,-1,${start_x.text},${start_y.text -
                  500 *
                    (index +
                      2.5 +
                      receptacleGroup.length +
                      probeGroup.length)})`
              "
              style="font-variant:normal;font-weight:normal;font-size:150px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
              :id="`gt${index}`"
              ><tspan x="0" y="0" id="tspan227">{{
                `${guide.description} - ${guide.part_number}, qty ${guide.count}`
              }}</tspan></text
            >
          </g>
        </g>
        <g v-show="btnToggle != 1">
          <text
            :transform="`matrix(1,0,0,-1,11000,12605)`"
            style="font-variant:normal;font-weight:bold;font-size:200px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
            id="text229"
            ><tspan x="0" :y="0" id="tspan227">Pressure Pins</tspan></text
          >
          <g
            v-for="(pressure, index) in pressurepinGroup"
            :key="`pressure${index}`"
          >
            <circle
              :cx="`${start_x.circle}`"
              :cy="`${start_y.circle - 500 * (index + 1)}`"
              r="200"
              :fill="pressure.color"
              :id="`g${index}`"
            />
            <text
              :transform="
                `matrix(1,0,0,-1,${start_x.text},${start_y.text -
                  500 * (index + 1)})`
              "
              style="font-variant:normal;font-weight:normal;font-size:150px;font-family:Arial;-inkscape-font-specification:ArialMT;writing-mode:lr-tb;fill:#000000;fill-opacity:1;fill-rule:nonzero;stroke:none"
              :id="`gt${index}`"
              ><tspan x="0" y="0" id="tspan227">{{
                `${pressure.description} - ${pressure.part_number}, qty ${pressure.count}`
              }}</tspan></text
            >
          </g>
        </g>
      </g></g
    >
  </g>
</template>
<script>
export default {
  name: "LegendGroup",
  props: {
    testpointPlot: Array,
    guidePins: Array,
    receptacleGroup: Array,
    probeGroup: Array,
    guidepinGroup: Array,
    pressurepinGroup: Array,
    btnToggle: Number,
    sideToggle: Number,
  },
  data() {
    return {
      start_x: {
        circle: 11250,
        text: 11700,
      },
      start_y: {
        circle: 12605,
        text: 12555,
      },
      /* receptacleColor: [
        "#100c05",
        "#dd4040",
        "#1ac8ed",
        "#084372",
        "#ff70fd",
        "#422c24",
      ],
      probeColor: [
        "#FFD700",
        "#824c71",
        "#b4a0e5",
        "#dccca3",
        "#d6fff6",
        "#4dccbd",
      ],
      guideColor: [
        "#0019FF",
        "#0069FF",
        "#008DFF",
        "#00A4FF",
        "#00B7DE",
        "#00C894",
      ], */
    };
  },
};
</script>
